import React, { useState, useEffect, useRef } from 'react';
import pdfOne from "./assets/icons/pdfOne.png"
import saveOne from "./assets/icons/saveOne.png"
import { LogoutIcon } from "@heroicons/react/outline";
import List from './components/list';
import quoteOne from "./assets/icons/quoteOne.png"
import { BeakerIcon } from '@heroicons/react/solid';
import checkOne from "./assets/icons/checkOne.png"
import { SearchIcon, SparklesIcon } from '@heroicons/react/solid';
import checkgreenOne from "./assets/icons/checkGreenOne.png"
import { HiMiniArrowTopRightOnSquare } from "react-icons/hi2";
import { HiPaperAirplane } from "react-icons/hi";
import axios from "axios";
import UserInfoPopup from "./components/userinfo"
import { FaSpinner, FaTimesCircle } from 'react-icons/fa';
import Header from "./components/header"
import Footer from './components/footer';
import ScrollToTop from "./scroll"
import { FaCommentAlt } from 'react-icons/fa';
import { RiFilePdf2Fill } from "react-icons/ri"
import { IoArrowBackCircleOutline, IoSparklesSharp } from 'react-icons/io5';
import { PiArrowCircleLeft, PiBookBookmarkBold, PiBookmarksSimpleFill, PiCheckCircleBold, PiCopyBold, PiPlusCircleBold, PiQuotesBold, PiSparkleBold, PiChatsTeardrop } from 'react-icons/pi';
import { FaChevronDown, FaSearch } from 'react-icons/fa';
import { auth } from "./firebase";
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from "./firebase"
import { useNavigate } from "react-router-dom";
import { XCircleIcon, UserCircleIcon, XIcon } from "@heroicons/react/solid";
import Home from './home';
import { SparkleIcon } from 'lucide-react';


const topJournals = [
    "Nature",
    "The New England Journal of Medicine",
    "Science",
    "Conference on Computer Vision and Pattern Recognition",
    "The Lancet",
    "Nature Communications",
    "Advanced Materials",
    "Cell",
    "Neural Information Processing Systems",
    "International Conference on Learning Representations",
    "JAMA",
    "Science of The Total Environment",
    "Nature Medicine",
    "Proceedings of the National Academy of Sciences",
    "Angewandte Chemie International Edition",
    "Chemical Reviews",
    "International Conference on Machine Learning",
    "Chemical Society Reviews",
    "Journal of Cleaner Production",
    "Nucleic Acids Research",
    "European Conference on Computer Vision",
    "Advanced Energy Materials",
    "Journal of the American Chemical Society",
    "IEEE Access",
    "Advanced Functional Materials",
    "Renewable and Sustainable Energy Reviews",
    "ACS Nano",
    "BMJ",
    "Physical Review Letters",
    "International Journal of Molecular Sciences",
    "Journal of Clinical Oncology",
    "AAAI Conference on Artificial Intelligence",
    "Science Advances",
    "PLoS ONE",
    "Frontiers in Immunology",
    "Scientific Reports",
    "Circulation",
    "Chemical Engineering Journal",
    "Energy & Environmental Science",
    "Applied Catalysis B: Environmental",
    "International Journal of Environmental Research and Public Health",
    "The Lancet Oncology",
    "Journal of the American College of Cardiology",
    "Meeting of the Association for Computational Linguistics",
    "Nutrients",
    "Nature Genetics",
    "Morbidity and Mortality Weekly Report",
    "Applied Energy",
    "Nature Biotechnology",
    "Sustainability",
    "Nano Energy",
    "Joule",
    "Journal of Materials Chemistry A",
    "Nature Materials",
    "IEEE Transactions on Pattern Analysis and Machine Intelligence",
    "ACS applied materials & interfaces",
    "Nature Energy",
    "ACS Catalysis",
    "The Lancet Infectious Diseases",
    "Conference on Empirical Methods in Natural Language Processing",
    "Journal of Business Research",
    "Gastroenterology",
    "European Heart Journal",
    "IEEE Internet of Things Journal",
    "Nature Nanotechnology",
    "Environmental Pollution",
    "The Astrophysical Journal",
    "Environmental Science & Technology",
    "Frontiers in Psychology",
    "Immunity",
    "Sensors",
    "Annals of Oncology",
    "ACS Energy Letters",
    "Journal of Hazardous Materials",
    "Communications Surveys & Tutorials",
    "Nature Neuroscience",
    "Gut",
    "Molecular Cancer",
    "Molecules",
    "Small",
    "Clinical Infectious Diseases",
    "Nature Methods",
    "Accounts of Chemical Research",
    "IEEE Transactions on Industrial Informatics",
    "Physical Review D",
    "Bioresource Technology",
    "American Economic Review",
    "Cell Metabolism",
    "Monthly Notices of the Royal Astronomical Society",
    "Chemosphere",
    "Blood",
    "Cell Reports",
    "Nano Letters",
    "Advanced Science",
    "Journal of High Energy Physics",
    "Nature Reviews Immunology",
    "Technological Forecasting and Social Change",
    "Frontiers in Microbiology",
    "Water Research",
]

const Papers = ({ handleSelectLanguage, setSavedStates, savedStates, selectedLanguage, savedDocs, setSelectedLanguage, showModal, setShowModal, user, userName, userAge, signIn }) => {
    const [questionx, setQuestionx] = useState("");
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };

    const [firstSearch, setFirstSearch] = useState(false)


    const [chatDoc, setChatDoc] = useState(false)
    const [followup, setFollowup] = useState(false)
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [selectedPaper, setSelectedPaper] = useState(null)
    const [showDetails, setShowDetails] = useState(false);
    const [mainWebsite, setMainWebsite] = useState(true)
    const [comparePapers, setComparePapers] = useState(false)
    const [deepSearch, setDeepSearch] = useState(false);
    const [indChat, setIndChat] = useState(true)

    const handleDocumentSelection = (paper) => {
        scrollToTop()
        setSelectedDocument(paper);
        setShowDetails(true);
        setChatDoc(false)
        setComparePapers(false)
        setMainWebsite(false)
        setFollowup(false)
    };

    const individualChat = () => {
        setComparePapers(false)
        setIndChat(true)
    }

    const showComparePapers = () => {
        setIndChat(false)
        setComparePapers(true)
    }

    const [savePaper, setSavePaper] = useState(false)

    const closeDetails = () => {
        setShowDetails(false);
        setMainWebsite(true)
        setFollowup(false)
        setChatDoc(false)
        setComparePapers(false)
        setSavePaper(false)
    };

    const handlePaperSelection = (e, paper) => {
        console.log("Paper details", paper?.openAccessPdf?.url || paper?.url)
        scrollToTop()
        setSelectedPaper(paper)
        setChatDoc(true)
        setComparePapers(false)
        setShowDetails(false)
        setFollowup(false)
        setMainWebsite(false)
        downloadPDF(e, paper)
    }

    const [searchTerm, setSearchTerm] = useState('')
    const [loading, setLoading] = useState(false);
    const [recommendations, setRecommendations] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    const [trOpen, setTrOpen] = useState(false)

    const clear = () => {
        setSearchTerm("")
    }


    const handleLogout = () => {
        auth.signOut();
    };

    const [fieldsOfStudy, setFieldsOfStudy] = useState([]);
    const [publicationTypes, setPublicationTypes] = useState([]);
    const handleCheckboxChange = (event, type) => {
        const { checked, value } = event.target;
        if (type === 'field') {
            setFieldsOfStudy(prev =>
                checked ? [...prev, value] : prev.filter(item => item !== value)
            );
        } else if (type === 'publication') {
            setPublicationTypes(prev =>
                checked ? [...prev, value] : prev.filter(item => item !== value)
            );
        }
    };

    const Navigate = useNavigate()

    const [enabled, setEnabled] = useState(() => {
        const saved = localStorage.getItem('enabledState');
        return saved === null ? true : saved === 'true';
    });

    useEffect(() => {
        localStorage.setItem('enabledState', enabled);
    }, [enabled]);


    const [free, setFree] = useState("")

    useEffect(() => {
    }, [fieldsOfStudy]);

    useEffect(() => {
    }, [publicationTypes]);

    const queriesRef = collection(db, "queries");

    const trackear = async (e) => {

        try {
            await addDoc(queriesRef, {
                query: searchTerm,
            });
        }
        catch {

        }
    }

    const [year, setYear] = useState("")
    const [yearRange, setYearRange] = useState("")
    const [yearBefore, setYearBefore] = useState("")
    const [yearAfter, setYearAfter] = useState("")
    const [context, setContext] = useState("")
    const [webResults, setWebResults] = useState(false);


    const [data, setData] = useState({
        summary: '',
        yes: '',
        maybe: '',
        no: '',
        answer: "",
        responses: []
    });

    const [summaryx, setSummaryX] = useState("")

    const handleSearch = async (e, overrideSearchTerm = null) => {
        if (e) e.preventDefault();

        const searchTermx = overrideSearchTerm || searchTerm;
        setLoading(true);
        setFirstSearch(true);
        setSummaryX("");
        setWebResults(false);

        try {
            const encodedSearchTerm = encodeURIComponent(searchTermx);
            const userUID = user?.uid || `anonymous-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

            const response = await fetch(
                `https://vortixlol.gaia-ml.com/api/search-one/?query=${encodedSearchTerm}&selectedLanguage=${selectedLanguage}&stream=true&deepSearch=${deepSearch}&userUID=${userUID}`
            );

            if (!response.ok) {
                throw new Error('Failed to fetch search results');
            }
            console.log(response)
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let buffer = '';

            while (true) {
                const { value, done } = await reader.read();
                if (done) break;

                buffer += decoder.decode(value, { stream: true });
                const lines = buffer.split('\n');
                for (let i = 0; i < lines.length - 1; i++) {
                    const line = lines[i].trim();
                    if (line.startsWith('data: ')) {
                        try {
                            const data = JSON.parse(line.slice(6));

                            if (data.type === 'search_results') {
                                setRecommendations(data?.data);
                                console.log(data?.data)
                                setContext(data.data.slice(0, 1));
                                const hasWebResults = data.data.some(paper =>
                                    paper.venue?.toLowerCase().includes('web') ||
                                    paper.url?.includes('http')
                                );
                                setWebResults(hasWebResults);
                                setLoading(false);
                            } else if (data.type === 'ai_response') {
                                setSummaryX(prev => prev + data.data);
                            }
                        } catch (e) {
                            console.error('Error parsing JSON:', e);
                        }
                    }
                }
                buffer = lines[lines.length - 1];
            }

        } catch (error) {
            console.error('Error fetching search data:', error);
            setSummaryX(selectedLanguage === 'es-ES' ?
                "Se produjo un error al obtener los resultados." :
                "An error occurred while fetching the results."
            );
            setLoading(false);
        }
    };

    const trBar = useRef()

    const handleClicko = (event) => {
        if (trBar.current && !trBar.current.contains(event.target)) {
            setTrOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClicko);
        return () => {
            document.removeEventListener('mousedown', handleClicko);
        };
    }, []);


    const sidebarRef = useRef();

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [messages, setMessages] = useState([]);

    const [getPDF, setGetPDF] = useState(false)

    const [displayError, setDisplayError] = useState(false)

    const [ready, setReady] = useState(false)

    const downloadPDF = async (e, paper) => {
        setGetPDF(true)
        const folderName = user?.email
        if (e) e.preventDefault();
        const url = paper?.openAccessPdf?.url || paper?.url;
        const type = paper?.type;
        console.log("Se descargará: ", url)
        try {
            const response = await axios.post("https://vortex-backend-wyg2.onrender.com/download", { url, type, folderName });
            console.log(response);
            setGetPDF(false)
            setReady(true)
        } catch (err) {
            setDisplayError(true)
            console.error(err);
        } finally {
        }
    };

    const [loadRequest, setLoadRequest] = useState(false)

    const [saved, setSaved] = useState(false)

    const saveRef = collection(db, "s3");

    const saveToFirebase = async (paper) => {
        if (!user || !user.email) {
            signIn();
            return;
        }

        try {
            await addDoc(saveRef, {
                ...paper,
                id: paper.url.split('/').pop(),
                uid: user.uid,
                timestamp: serverTimestamp()
            });
            // Update saved state for this specific paper
            setSavedStates(prev => ({
                ...prev,
                [paper.url]: true
            }));
        } catch (error) {
            alert(error);
        }
    };

    const handleSaving = (paper) => {
        console.log("Guardando: ", paper);
        saveToFirebase(paper);
    };

    const [showCopiedMessage, setShowCopiedMessage] = useState(false);
    const [citationPopup, setCitationPopup] = useState({ show: false, citation: '' });

    const formatCitationAPA = (selectedDocument) => {
        const authors = selectedDocument?.author?.name || selectedDocument?.author || "";
        const year = selectedDocument?.year || "";
        const title = selectedDocument?.title || "";
        const journal = selectedDocument?.journal?.name || "";
        const volume = selectedDocument?.volume || "";
        const pages = selectedDocument?.pages || "";
        const url = selectedDocument?.openAccessPdf?.url || selectedDocument?.url;
        return `${authors} (${year}). ${title}. <i>${journal}</i>, ${volume}, ${pages} <br/> <p style="color: #2563eb;">${url}</p>`;
    };

    const showCitationPopup = (selectedDocument) => {
        const citation = formatCitationAPA(selectedDocument);
        setCitationPopup({ show: true, citation });
    };

    const closeCitationPopup = () => {
        setCitationPopup({ show: false, citation: '' });
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setShowCopiedMessage(true);
        setTimeout(() => {
            setShowCopiedMessage(false);
        }, 1500);
    };

    const [Topic, setTopic] = useState(false);
    const [Summary, setSummary] = useState(false);
    const [Findings, setFindings] = useState(false);
    const [Limitations, setLimitations] = useState(false);
    const [outcomeMeasured, setOutcomeMeasured] = useState(false);
    const [customValue, setCustomValue] = useState("");
    const [apiAnswer, setApiAnswer] = useState("");
    const [customFields, setCustomFields] = useState([]);

    const handleCustomFieldChange = (event) => {
        if (event.key !== 'Enter') {
            return;
        }
        if (customValue.trim() !== "") {
            setCustomFields([...customFields, customValue.trim()]);
            setCustomValue("");
        }
    };

    const continueChat = () => {
        Navigate("/chat")
    }


    const removeCustomField = (field) => {
        setCustomFields(customFields.filter((f) => f !== field));
    };

    const instruction = selectedLanguage === "es-ES" ? `Extrae la siguiente información sobre el artículo: ${Topic ? "Tema principal, " : ""}${Limitations ? "Limitaciones, " : ""}${outcomeMeasured ? "Resultado medido, " : ""}${Summary ? "Resumen, " : ""}${Findings ? "Hallazgos, " : ""}${customFields.join(", ")}. Intenta lo mejor posible para devolver una respuesta incluso si el artículo no es académico o no contiene todos los campos, si es así, devuelve esos campos como cadenas vacías. Devuelve tu respuesta en el siguiente formato: data=["paper_1"={"topic":"metabolismo en humanos y ...","summary":"El estudio muestra que","main_findings":"..."}]. Siempre devuelve una respuesta con al menos algunos de los campos solicitados. El artículo no tiene que incluir párrafos específicos sobre el resumen, el tema, la metodología, etc. Debes generar estos campos resumiendo el artículo, analizando qué métodos siguieron los autores, qué limitaciones enfrentó o podría enfrentar el estudio, etc.` : `Extract the following information about the paper: ${Topic ? "Main Topic, " : ""}${Limitations ? "Limitations, " : ""}${outcomeMeasured ? "Outcome Measured, " : ""}${Summary ? "Summary, " : ""}${Findings ? "Findings, " : ""}${customFields.join(", ")}. Try your best to return an answer even if the papers is not academic or does not contain all the fields, if so return those fields as empty strings. Return your answer in the following format: data=["paper_1"={"topic":"metabolism in humans and ...","summary":"The study shows that","main_findings":"..."}]. Always return an answer with at least some of the fields asked. The paper do not have to include specific paragraphs about the summary, topic, methodoly, etc you should generate these fields by summarizing the paper, analyzing what methods the authors followed, what limitations the study faced or could face, etc..`

    const [retrievingCustomData, setRetrievingCustomData] = useState(false)

    const sendRequest = async (e, selectedPaper) => {
        e.preventDefault();
        const folderName = user?.email;
        const type = selectedPaper?.type;
        console.log("question is: ", questionx);
        setLoadRequest(true);
        setMessages(messages => [
            ...messages,
            { type: 'user', text: questionx },
            { type: 'api', text: 'loading' }
        ]);
        setQuestionx("");

        try {
            const response = await fetch("https://vortex-backend-wyg2.onrender.com/ask-paper", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query: { type, question: questionx, userName, userAge, folderName },
                }),
            });

            const reader = response.body.getReader();
            const textDecoder = new TextDecoder('utf-8');
            let answer = '';

            async function readChunk() {
                const { value, done } = await reader.read();
                if (done) {
                    console.log('Streaming complete');
                    return;
                }
                const decodedChunk = textDecoder.decode(value, { stream: true });
                console.log('Received chunk:', decodedChunk);
                answer += decodedChunk;

                setMessages(messages => {
                    const newMessages = [...messages];
                    const lastMessage = newMessages[newMessages.length - 1];
                    if (lastMessage.type === 'api') {
                        lastMessage.text = answer;
                    } else {
                        newMessages.push({ type: 'api', text: answer });
                    }
                    return newMessages;
                });

                await readChunk();
            }

            await readChunk();
        } catch (error) {
            console.error(error);
        } finally {
            setLoadRequest(false);
            setQuestionx("");
        }
    };

    const sendRequest2 = async (e) => {
        e.preventDefault();
        const folderName = user?.email;
        setRetrievingCustomData(true);
        console.log(selectedLanguage);
        const question = instruction;
        setApiAnswer('');

        try {
            const response = await fetch("https://vortex-backend-wyg2.onrender.com/ask-paper", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query: { question, folderName },
                }),
            });

            const reader = response.body.getReader();
            const textDecoder = new TextDecoder('utf-8');
            let answer = '';
            async function readChunk() {
                const { value, done } = await reader.read();
                if (done) {
                    console.log('Streaming complete');
                    return;
                }

                const decodedChunk = textDecoder.decode(value, { stream: true });
                console.log('Received chunk:', decodedChunk);
                answer += decodedChunk;
                setApiAnswer((prevAnswer) => prevAnswer + decodedChunk);

                await readChunk();
            }

            await readChunk();
        } catch (error) {
            console.error(error);
        } finally {
            setRetrievingCustomData(false);
        }
    };

    const getData = (apiAnswer) => {
        const regex = /"(\w+)"\s*:\s*"(.*?)"/g;
        const paperData = {};

        let match;
        while ((match = regex.exec(apiAnswer)) !== null) {
            const key = match[1];
            const value = match[2];
            paperData[key] = value;
        }

        return paperData;
    };

    const paperData = getData(apiAnswer);

    const [showPopup, setShowPopup] = useState(false);
    const [userInfo, setUserInfo] = useState(null);

    const closePopup = () => {
        setShowPopup(false);
    };
    const handleUserInfoSubmit = (info) => {
        setUserInfo(info);
    };

    const clearAll = () => {
        setFirstSearch(false)
        setSearchTerm("")
    }

    const closeChat = () => {
        setIndChat(true)
        setComparePapers(false)
        setChatDoc(false)
        setShowDetails(false)
        setComparePapers(false)
        setMainWebsite(true)
        setMessages([])
        setGetPDF(false)
        setReady(false)
        setDisplayError(false)
        setApiAnswer("")
    }

    const citationRefs = useRef([]);

    const scrollToReference = (index) => {
        citationRefs.current[index]?.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });
    };

    const formatTextWithCitations = (text) => {
        const parts = text.split(/(\[\d+\])/g);
        return parts.map((part, index) => {
            const match = part.match(/\[(\d+)\]/);
            if (match) {
                const citationNumber = parseInt(match[1]);
                return (
                    <button
                        key={index}
                        onClick={() => scrollToReference(citationNumber - 1)}
                        className="inline-flex items-center justify-center w-6 h-6 text-sm font-medium bg-indigo-100 text-indigo-800 rounded-full hover:bg-indigo-200 transition-colors mx-1"
                    >
                        {citationNumber}
                    </button>
                );
            }

            // Process markdown in non-citation text
            let processedText = part;

            // Bold
            processedText = processedText.split(/(\*\*.*?\*\*)/g).map((segment, segIndex) => {
                const boldMatch = segment.match(/^\*\*(.*?)\*\*$/);
                if (boldMatch) {
                    return <strong key={`${index}-bold-${segIndex}`}>{boldMatch[1]}</strong>;
                }
                return segment;
            });

            // Italic
            processedText = processedText.map(segment => {
                if (typeof segment !== 'string') return segment;
                return segment.split(/(\*.*?\*)/g).map((italicSegment, italicIndex) => {
                    const italicMatch = italicSegment.match(/^\*(.*?)\*$/);
                    if (italicMatch) {
                        return <em key={`${index}-italic-${italicIndex}`}>{italicMatch[1]}</em>;
                    }
                    return italicSegment;
                });
            });

            // Inline code
            processedText = processedText.map(segment => {
                if (typeof segment !== 'string') return segment;
                return segment.split(/(`.*?`)/g).map((codeSegment, codeIndex) => {
                    const codeMatch = codeSegment.match(/^`(.*?)`$/);
                    if (codeMatch) {
                        return <code key={`${index}-code-${codeIndex}`} className="bg-gray-100 px-1 rounded">{codeMatch[1]}</code>;
                    }
                    return codeSegment;
                });
            });

            const flattenedText = processedText.flat(2).filter(item => item !== '');

            return <span key={index}>{flattenedText}</span>;
        });
    };

    return (
        <>
            {!firstSearch && <Home handleLogout={handleLogout} deepSearch={deepSearch} setDeepSearch={setDeepSearch} handleSearch={handleSearch} searchTerm={searchTerm} setSearchTerm={setSearchTerm} signIn={signIn} user={user} handleSelectLanguage={handleSelectLanguage} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} showModal={showModal} setShowModal={setShowModal} />}
            {showPopup && (
                <UserInfoPopup signIn={signIn} user={user} selectedLanguage={selectedLanguage} onClose={closePopup} onSubmit={handleUserInfoSubmit} />
            )}
            {firstSearch &&
                <>
                    <ScrollToTop />
                    {showDetails && selectedDocument && (
                        <>
                            {citationPopup.show && (
                                <div className="fixed inset-0 flex items-center justify-center z-50">
                                    <div className="modal-overlay absolute w-full h-full bg-gray-900 bg-opacity-50"></div>
                                    <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto shadow-xl z-50">
                                        <div className="">
                                            <div className="w-full flex bg-gray-100">
                                                <div className="w-1/6"></div>
                                                <div className="w-4/6 flex justify-center py-3">
                                                    <p className="text-lg font-semibold text-gray-700">
                                                        {selectedLanguage === "es-ES" ? "Citar fuente" : "Cite source"}
                                                    </p>
                                                </div>
                                                <div className="w-1/6 flex items-center justify-center">
                                                    <XCircleIcon
                                                        onClick={closeCitationPopup}
                                                        className="text-gray-700 h-6 w-6 hover:text-gray-800 cursor-pointer"
                                                    />
                                                </div>
                                            </div>
                                            <div className="text-gray-600 rounded bg-gray-100 text-sm light p-6 relative">
                                                <div
                                                    className="citation-text font-sans break-words"
                                                    dangerouslySetInnerHTML={{
                                                        __html: citationPopup.citation
                                                    }}
                                                ></div>
                                                <PiCopyBold
                                                    className="my-2 w-5 h-5 cursor-pointer hover:text-gray-800 text-gray-600"
                                                    onClick={() =>
                                                        copyToClipboard(
                                                            citationPopup[`citation${citationPopup.selectedTab}`]
                                                        )
                                                    }
                                                    title="Copy to clipboard"
                                                />
                                                {showCopiedMessage && (
                                                    <div className="absolute bottom-2 border rounded-full left-1/2 transform -translate-x-1/2 bg-white border-green-500 text-green-500 px-5 py-2 rounded">
                                                        {selectedLanguage === "es-ES" ? "Texto copiado" : "Text copied"}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <Header />
                            <div className="w-full bg-gray-200 py-20 flex justify-center">
                                <div className='w-11/12 sm:w-3/4 p-4 sm:p-12 bg-white rounded-2xl'>
                                    <div className="sm:px-6 px-2 py-3">
                                        <IoArrowBackCircleOutline className='w-7 h-auto text-gray-600 hover:text-gray-800 cursor-pointer' onClick={closeDetails} />
                                    </div>
                                    <div className="p-2 sm:flex">
                                        <div className="w-full sm:w-1/3 sm:p-6">
                                            <div className="pb-6 border-b w-full border-gray-300">
                                                <a href={selectedDocument?.url} target='_blank' className="text-2xl cursor-pointer hover:underline font-medium pb-2">{selectedDocument.translatedTitle}</a>
                                                <div className="text-sm pt-2 flex">
                                                    <p className='text-gray-600'>{selectedDocument?.author} - {selectedDocument?.year}</p>
                                                </div>
                                                <div className="grid w-5/6 py-5 grid-cols-3">
                                                    <div className="flex justify-center">
                                                        <button href={selectedDocument?.url} target='_blank' rel="noopener noreferrer">
                                                            <RiFilePdf2Fill className="w-5 h-auto text-red-500 cursor-pointer hover:text-red-700" />
                                                        </button>

                                                    </div>
                                                    <div className="flex justify-center">
                                                        <PiQuotesBold
                                                            onClick={() => showCitationPopup(selectedDocument)}
                                                            className="w-5 h-auto text-gray-600 cursor-pointer hover:text-gray-800" />
                                                    </div>
                                                    {savePaper ?
                                                        <div className="flex justify-center">
                                                            <PiCheckCircleBold
                                                                className="h-5 w-auto text-green-500" />
                                                        </div> :
                                                        <div className="flex justify-center">
                                                            <button className='px-3 py-2 rounded-2xl bg-gray-200 flex items-center hover:bg-gray-100'>
                                                                <PiBookBookmarkBold
                                                                    onClick={() => saveToFirebase(selectedDocument)}
                                                                    className="w-5 h-auto text-gray-600 cursor-pointer hover:text-gray-800" />
                                                                <p className='text ml-2 text-gray-600 font-medium'>Save</p>
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="w-full py-6">
                                                {selectedDocument?.journal?.name
                                                    &&
                                                    <div className="w-full py-3 px-1">
                                                        <div>
                                                            <p className="text-gray-500 font-medium"> {selectedLanguage === "es-ES" ? "Revista" : "Journal Name"} </p>
                                                        </div>
                                                        <div>
                                                            <p className='font-medium text-lg'>{selectedDocument?.journal?.name}</p>
                                                        </div>
                                                    </div>
                                                }
                                                {selectedDocument?.citationCount &&
                                                    <div className="w-full grid py-3 grid-cols-2">
                                                        <div>
                                                            <p className="text-gray-500 font-medium">{selectedLanguage === "es-ES" ? "Citas" : "Cites"}</p>
                                                        </div>
                                                        <div>
                                                            <p className='font-medium'> {selectedDocument?.citationCount} </p>
                                                        </div>
                                                    </div>
                                                }
                                                {selectedDocument?.fieldsOfStudy &&
                                                    <div className="w-full grid py-3 grid-cols-">
                                                        <div>
                                                            <p className="text-gray-500 font-medium">{selectedLanguage === "es-ES" ? "Campos de estudio" : "Fields of study"} </p>
                                                        </div>
                                                        <div>
                                                            <p className='font-medium'> {selectedDocument?.fieldsOfStudy.join(", ")} </p>
                                                        </div>
                                                    </div>
                                                }
                                                {selectedDocument?.journal?.pages &&
                                                    <div className="w-full grid py-3 grid-cols-2 px-1">
                                                        <div>
                                                            <p className="text-gray-500 font-medium"> {selectedLanguage === "es-ES" ? "Páginas" : "Pages"} </p>
                                                        </div>
                                                        <div>
                                                            <p className='font-medium'>{selectedDocument?.journal?.pages}</p>
                                                        </div>
                                                    </div>
                                                }
                                                {selectedDocument?.journal?.volume &&
                                                    <div className="w-full grid py-3 grid-cols-2 px-1">
                                                        <div>
                                                            <p className="text-gray-500 font-medium"> {selectedLanguage === "es-ES" ? "Volúmen" : "Volume"} </p>
                                                        </div>
                                                        <div>
                                                            <p className='font-medium'>{selectedDocument?.journal?.volume}</p>
                                                        </div>
                                                    </div>
                                                }
                                                {selectedDocument?.referenceCount &&
                                                    <div className="w-full grid py-3 grid-cols-2 px-1">
                                                        <div>
                                                            <p className="text-gray-500 font-medium"> {selectedLanguage === "es-ES" ? "Referencias" : "Reference Count"} </p>
                                                        </div>
                                                        <div>
                                                            <p className='font-medium'>{selectedDocument?.referenceCount}</p>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full sm:border-l border-gray-300 sm:w-2/3 sm:p-6">
                                            <div className='flex items-center'>
                                                <a href={selectedDocument?.url} target="_blank">
                                                    <button className="flex items-center"> <HiMiniArrowTopRightOnSquare className="w-6 h-auto text-green-500 hover:text-green-700 cursor-pointer" /> {selectedLanguage === "es-ES" ? "Ir al documento" : "Go to source"} </button>
                                                </a>
                                            </div>
                                            <p className="text-gray-500 font-semibold pt-6 text-lg pb-2">Abstract</p>
                                            <p className="text-justify"> {selectedDocument?.translatedAbstract} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </>
                    )}
                    {chatDoc && selectedPaper && (
                        <>
                            <div className='w-full flex min-h-screen'>
                                <aside className="w-0 sm:w-1/6 bg-gray-100 p-4 hidden sm:flex flex-col">
                                    <nav>
                                        <ul className="px-4 py-8">
                                            <li className="py-5">
                                                <a onClick={closeChat} className="text-gray-600 hover:text-gray-800 cursor-pointer font-semibold flex items-center">
                                                    <PiArrowCircleLeft className="inline-block w-5 h-auto mr-2" />
                                                    {selectedLanguage === "es-ES" ? "Regresar" : "Go back"}
                                                </a>
                                            </li>
                                            <li className="py-5">
                                                <a href="" className="text-gray-600 hover:text-gray-800 cursor-pointer font-semibold flex items-center">
                                                    <PiPlusCircleBold className="inline-block w-5 h-auto mr-2" />
                                                    {selectedLanguage === "es-ES" ? "Chat nuevo" : "New chat"}
                                                </a>
                                            </li>
                                            {indChat &&
                                                <button onClick={showComparePapers} className="py-5">
                                                    <a className="text-gray-600 font-semibold">
                                                        <PiSparkleBold className="inline-block w-5 h-auto mr-2 text-gray-600" />
                                                        {selectedLanguage === "es-ES" ? "Extraer info" : "Extract"}
                                                    </a>
                                                </button>}

                                            {comparePapers &&
                                                <button className="py-5" onClick={individualChat}>
                                                    <a className="text-gray-600 font-semibold">
                                                        <PiSparkleBold className="inline-block w-5 h-auto mr-2 text-gray-600" />
                                                        Extract
                                                    </a>
                                                </button>}
                                        </ul>
                                    </nav>
                                    <div className="flex-grow overflow-y-auto mt-4">
                                        <h2 className="font-semibold text-lg mb-2">Chat History</h2>
                                    </div>
                                </aside>
                                <div className='bg-white p-2 rounded-xl sm:px-8 sm:pt-16 w-full sm:w-5/6 flex flex-col'>
                                    <div>
                                        <div className='sm:flex items-center pt-2 pb-6'>
                                            <div className='w-full pb-6 sm:pb-0 sm:pr-2 sm:w-3/5'>
                                                <a target='_blank' href={selectedPaper?.openAccessPdf?.url}
                                                    className='text-blue-600 line-clamp-2 font-medium text-sm sm:text-lg cursor-pointer hover:underline'>
                                                    {selectedPaper?.translatedTitle}</a>
                                            </div>
                                            <div className='w-1/2 flex items-center justify-evenly sm:w-2/5'>

                                                <a target='_blank' href={selectedPaper?.openAccessPdf?.url} >
                                                    <img src={pdfOne} className='w-6 h-auto' />
                                                </a>
                                                {getPDF &&
                                                    <FaSpinner className='animate-spin w-4 sm:w-5 h-4 sm:h-5 sm:ml-2 text-gray-500' />
                                                }
                                                {ready &&
                                                    <img src={checkgreenOne} className='w-6 h-auto' />}
                                            </div>
                                        </div>
                                    </div>
                                    {displayError &&
                                        <div className='flex justify-center p-6'>
                                            <p className='font-semibold text-gray-600'> {selectedLanguage === "es-ES" ? "Ha occurido un error. Descargue el PDF manualmente y chatee con este en la sección Chat" : "An error has occurred. Download the PDF manually and chat with it in the Chat section"} </p>
                                        </div>
                                    }
                                    {indChat &&
                                        <div className='flex flex-col flex-grow'>
                                            <div className='flex-grow overflow-y-auto'>
                                                <div className="flex w-full flex-col gap-4">
                                                    {messages?.map((message, index) => (
                                                        <div key={index} className={`${message.type === "user" ? "text-justify sm:px-12 2xl:px-20 p-2 text-gray-500" : "text-justify"} p-2 sm:px-12 2xl:px-20`}>
                                                            {message.type === "user" ? (
                                                                <p className='text-gray-600 font-semibold'> {user?.displayName} </p>
                                                            ) : (
                                                                <p className='text-blue-600 font-semibold'>VortiX</p>
                                                            )}
                                                            <div>
                                                                {message.text != 'loading' && message.text}
                                                                {message.type === "api" && message.text === 'loading' && (
                                                                    <div className='flex w-full justify-start'>
                                                                        <div class=" shadow rounded-md m p-4 w-11/12 bg-white sm:w-1/2">
                                                                            <div class="animate-pulse flex space-x-4">
                                                                                <div class="flex-1 space-y-12 py-2">
                                                                                    <div class="space-y-3 py-8 border border-gray-100 rounded-lg">
                                                                                        <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                                        <div class="grid grid-cols-3 gap-4">
                                                                                            <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                                            <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                                            <div class="h-3 bg-indigo-200 rounded col-span-1"></div>
                                                                                        </div>
                                                                                        <div class="h-3 bg-indigo-200 rounded"></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className='bg-white px-4 sm:px-40 py-4 sticky bottom-0 shadow-md'>
                                                <form onSubmit={(e) => sendRequest(e, selectedPaper)} className="flex px-4 items-center rounded-3xl bg-gray-200">
                                                    <input
                                                        placeholder={selectedLanguage === "es-ES" ? "Empieza a escribir acá..." : "Start typing here..."}
                                                        value={questionx}
                                                        onChange={(e) => setQuestionx(e.target.value)}
                                                        className="flex-grow border bg-transparent rounded-3xl p-4 mr-4 outline-none text-gray-600"
                                                    />
                                                    <HiPaperAirplane
                                                        onClick={(e) => sendRequest(e, selectedPaper)}
                                                        className="w-6 h-6 text-gray-400 hover:text-gray-500" />
                                                </form>
                                            </div>
                                        </div>
                                    }
                                    {comparePapers &&
                                        <>
                                            <div className="w-full sm:w-1/2 py-6 flex items-center justify-start">
                                                <p className="font-semibold text-gray-600 mt-4 text-lg">
                                                    {selectedLanguage === "es-ES" ? "Selecciona el tipo de información que deseas extraer" : "Select the information you want to extract"}
                                                </p>
                                            </div>
                                            <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-4">
                                                <div className="flex items-center my-3">
                                                    <input type="checkbox" checked={Findings} onChange={() => setFindings(!Findings)} className="mr-2" />
                                                    <button className="px-5 py-1 rounded-3xl hover:bg-blue-200 text-blue-600 bg-blue-100">
                                                        {selectedLanguage === "es-ES" ? "Hallazgos principales" : "Main findings"}
                                                    </button>
                                                </div>
                                                <div className="flex items-center my-3">
                                                    <input type="checkbox" checked={Topic} onChange={() => setTopic(!Topic)} className="mr-2" />
                                                    <button className="px-5 py-1 rounded-3xl hover:bg-blue-200 text-blue-600 bg-blue-100">
                                                        {selectedLanguage === "es-ES" ? "Metodologías" : "Methodologies"}
                                                    </button>
                                                </div>
                                                <div className="flex items-center my-3">
                                                    <input type="checkbox" checked={outcomeMeasured} onChange={() => setOutcomeMeasured(!outcomeMeasured)} className="mr-2" />
                                                    <button className="px-5 py-1 rounded-3xl hover:bg-blue-200 text-blue-600 bg-blue-100">
                                                        {selectedLanguage === "es-ES" ? "Resultados medidos" : "Outcome measured"}
                                                    </button>
                                                </div>
                                                <div className="flex items-center my-3">
                                                    <input type="checkbox" checked={Limitations} onChange={() => setLimitations(!Limitations)} className="mr-2" />
                                                    <button className="px-5 py-1 rounded-3xl hover:bg-blue-200 text-blue-600 bg-blue-100">
                                                        {selectedLanguage === "es-ES" ? "Limitaciones" : "Limitations"}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-4 items-center">
                                                <div className="flex items-center py-3">
                                                    <input
                                                        value={customValue}
                                                        onChange={(e) => setCustomValue(e.target.value)}
                                                        onKeyDown={(e) => handleCustomFieldChange(e)}
                                                        className="rounded-2xl text-sm outline-none border border-blue-300 p-2 w-full"
                                                        placeholder="Add fields"
                                                    />
                                                </div>
                                                {customFields.length > 0 && (
                                                    customFields.map((field, index) => (
                                                        <div key={index} className="flex items-center my-3">
                                                            <FaTimesCircle
                                                                className="text-red-500 hover:text-red-700 cursor-pointer w-4 h-auto mr-2"
                                                                onClick={() => removeCustomField(field)}
                                                            />
                                                            <button className="px-5 py-1 rounded-3xl hover:bg-blue-200 text-blue-600 bg-blue-100">
                                                                {field}
                                                            </button>
                                                        </div>
                                                    ))
                                                )}
                                            </div>
                                            <div className="flex items-center justify-start pt-6 pb-12">
                                                <button
                                                    name="extractInfo"
                                                    onClick={sendRequest2}
                                                    className="border border-blue-500 text-blue-500 font-semibold px-5 py-2 rounded-3xl hover:bg-blue-100"
                                                >
                                                    {selectedLanguage === "es-ES" ? "Empezar" : "Start"}
                                                </button>
                                            </div>
                                            {retrievingCustomData && (
                                                <div className="flex justify-center">
                                                    <p className="py-8">
                                                        {selectedLanguage === "es-ES" ? "Cargando..." : "Loading..."}
                                                    </p>
                                                </div>
                                            )}
                                            {apiAnswer && (
                                                <div className="container mx-auto">
                                                    <div className="block sm:hidden">
                                                        {Object.entries(paperData).map(([key, value]) => (
                                                            <div key={key} className="border-b last:border-b-0">
                                                                <div className="font-bold py-2 px-4 bg-gray-100">{key}</div>
                                                                <div className="py-2 px-4">{value}</div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="hidden sm:block">
                                                        <table className="table-auto w-full">
                                                            <thead>
                                                                <tr>
                                                                    <th className="px-4 py-2">Field</th>
                                                                    <th className="px-4 py-2">Value</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Object.entries(paperData).map(([key, value]) => (
                                                                    <tr key={key}>
                                                                        <td className="border px-4 py-2 font-bold">{key}</td>
                                                                        <td className="border px-4 py-2">{value}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            )}
                                        </>

                                    }
                                </div>
                            </div>
                        </>

                    )}
                    {mainWebsite &&
                    <div className="flex flex-row h-screen bg-gray-50">
                        {/* Sidebar */}
                        <aside className="w-0 sm:w-64 bg-white border-r border-gray-100 overflow-hidden flex flex-col fixed h-full hidden sm:flex">
                            {/* Header Section */} 
                            <div className="flex justify-center py-6 border-b border-gray-100">
                                <div onClick={clearAll} className="flex cursor-pointer items-center space-x-3">
                                    <h1 className="text-xl font-bold text-gray-800">VortiX</h1>
                                    <div className="flex items-center bg-indigo-50 rounded-full px-3 py-1">
                                        <BeakerIcon className="w-4 h-4 text-indigo-600 mr-1" />
                                        <span className="text-xs font-medium text-indigo-600">Beta</span>
                                    </div>
                                </div>                            </div>

                            {/* List Component */}
                            <div className="flex-1 overflow-hidden">
                                <List signIn={signIn} user={user} selectedLanguage={selectedLanguage} savedDocs={savedDocs} />
                            </div>

                            {/* Fixed Profile Section */}
                            {user && (
                                <div className="fixed bottom-6 left-6 flex items-center space-x-2 bg-white px-3 py-2 rounded-full border border-gray-100 hover:border-gray-200 transition-colors">
                                    {user.photoURL ? (
                                        <img
                                            onClick={handleLogout}
                                            src={user.photoURL}
                                            className="w-8 h-8 rounded-full border border-gray-200 cursor-pointer"
                                            alt="User profile"
                                        />
                                    ) : (
                                        <UserCircleIcon onClick={handleLogout} className="w-8 h-8 text-gray-400 cursor-pointer" />
                                    )}
                                    <button onClick={handleLogout}
                                        className="text-sm flex items-center text-gray-600 hover:text-gray-900 transition-colors">
                                        <LogoutIcon className="w-4 h-4 text-red-400 mr-1.5" />
                                        Logout
                                    </button>
                                </div>
                            )}
                        </aside>

                        {/* Main Content */}
                        <div className="w-full flex justify-center h-full">
                            <div className="w-full sm:pl-64">
                                {/* Mobile Header */}
                                <div className="flex cursor-pointer sm:hidden pt-6 items-center justify-center">
                                    <h1 onClick={clearAll} className="text-xl font-bold text-gray-800">VortiX</h1>
                                    <div onClick={clearAll} className="flex items-center bg-indigo-50 rounded-full px-3 py-1">
                                        <BeakerIcon className="w-4 h-4 text-indigo-600 mr-1" />
                                        <span className="text-xs font-medium text-indigo-600">Beta</span>
                                    </div>
                                </div>

                                {/* Search Section */}
                                <div className="max-w-5xl mx-auto px-4 pt-8">
                                    <div className="flex flex-col md:flex-row items-center gap-4">
                                        <div className="w-full md:w-2/3">
                                            {/* Search Form */}
                                            <form onSubmit={(e) => { e.preventDefault(); if (!loading) { handleSearch(); } }}
                                                className="flex items-center space-x-3 w-full bg-white border border-gray-200 rounded-2xl p-2 hover:border-gray-300 transition-colors">
                                                <input
                                                    placeholder="Ask a follow-up question..."
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                    className="flex-1 bg-transparent px-3 py-2 outline-none text-gray-700"
                                                    disabled={loading}
                                                />
                                                <div className="flex items-center space-x-2 pr-2">
                                                    <button type="button" onClick={clear}
                                                        className="text-gray-400 hover:text-gray-600 transition-colors">
                                                        <XIcon className="w-5 h-5" />
                                                    </button>
                                                    <button type="submit" disabled={loading}
                                                        className="text-indigo-500 hover:text-indigo-600 transition-colors">
                                                        <FaSearch className="w-5 h-5" />
                                                    </button>
                                                </div>
                                            </form>

                                            {/* Deep Search Toggle */}
                                            <div className="mt-3 flex items-center">
                                                <label className="relative inline-flex items-center cursor-pointer">
                                                    <input
                                                        type="checkbox"
                                                        className="sr-only peer"
                                                        checked={deepSearch}
                                                        onChange={(e) => setDeepSearch(e.target.checked)}
                                                    />
                                                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-indigo-100 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-indigo-600"></div>
                                                </label>
                                                <span className="ml-3 text-sm font-medium text-gray-700">
                                                    {selectedLanguage === "es-ES" ? "Búsqueda profunda" : "Deep Search"}
                                                </span>
                                                <span className="ml-1 group relative">
                                                    <SparklesIcon className="w-4 h-4 text-indigo-600 cursor-help" />
                                                    <span className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 w-48 p-2 bg-gray-800 text-white text-xs rounded-lg opacity-0 group-hover:opacity-100 transition-opacity">
                                                        {selectedLanguage === "es-ES"
                                                            ? "Búsqueda en más de 30 artículos académicos"
                                                            : "Search across 30+ academic papers"}
                                                    </span>
                                                </span>
                                            </div>
                                        </div>

                                        {/* Action Buttons */}
                                        <div className="flex items-center justify-end space-x-3 w-full md:w-1/3">
                                            <PiBookmarksSimpleFill
                                                onClick={() => Navigate("/saved")}
                                                className="h-8 w-8 text-indigo-600 cursor-pointer hover:text-indigo-500 transition-colors"
                                            />
                                        </div>
                                    </div>

                                    {/* Content Section */}
                                    <div className="mt-8">
                                        {/* Summary Header */}
                                        <div className="bg-white rounded-xl p-4 border border-gray-100">
                                            <div className="flex items-center mb-4">
                                                <SparkleIcon className={`w-5 h-5 text-indigo-500 ${loading ? 'animate-spin' : ''}`} />
                                                <span className="ml-2 font-medium text-gray-700">
                                                    {selectedLanguage === "es-ES" ? "Resúmen" : "Summary"}
                                                </span>
                                            </div>

                                            {loading ? (
                                                <div className="space-y-3 py-4">
                                                    <div className="h-3 bg-indigo-100 rounded-full w-full"></div>
                                                    <div className="h-3 bg-indigo-100 rounded-full w-5/6"></div>
                                                    <div className="h-3 bg-indigo-100 rounded-full w-4/6"></div>
                                                </div>
                                            ) : (
                                                <div className="prose max-w-none">
                                                    <p className="text-gray-600 text-justify whitespace-pre-wrap">
                                                        {formatTextWithCitations(summaryx)}
                                                    </p>
                                                </div>
                                            )}
                                        </div>

                                        {/* Loading Skeletons or Results */}
                                        {loading ? (
                                            <div className="space-y-4 mt-4">
                                                {[...Array(6)].map((_, index) => (
                                                    <div key={index} className="bg-white rounded-xl p-6 border border-gray-100">
                                                        <div className="animate-pulse space-y-4">
                                                            <div className="h-4 bg-indigo-100 rounded-full w-3/4"></div>
                                                            <div className="space-y-2">
                                                                <div className="h-3 bg-indigo-100 rounded-full w-full"></div>
                                                                <div className="h-3 bg-indigo-100 rounded-full w-5/6"></div>
                                                                <div className="h-3 bg-indigo-100 rounded-full w-4/6"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <Result
                                                citationRefs={citationRefs}
                                                savedStates={savedStates}
                                                savedPapers={savedDocs}
                                                saved={saved}
                                                handleSaving={handleSaving}
                                                saveToFirebase={saveToFirebase}
                                                handlePaperSelection={handlePaperSelection}
                                                handleDocumentSelection={handleDocumentSelection}
                                                data={data}
                                                signIn={signIn}
                                                user={user}
                                                selectedLanguage={selectedLanguage}
                                                recommendations={recommendations}
                                            />
                                        )}
                                    </div>
                                </div>

                                {/* Modals */}
                                {trOpen && (
                                    <Traduccion
                                        trOpen={trOpen}
                                        selectedLanguage={selectedLanguage}
                                        trBar={trBar}
                                        setTrOpen={setTrOpen}
                                    />
                                )}
                                {isOpen && (
                                    <Sidebar
                                        yearRange={yearRange}
                                        yearBefore={yearBefore}
                                        yearAfter={yearAfter}
                                        setYearRange={setYearRange}
                                        setYearBefore={setYearBefore}
                                        setYearAfter={setYearAfter}
                                        year={year}
                                        setYear={setYear}
                                        handleCheckboxChange={handleCheckboxChange}
                                        handleSearch={handleSearch}
                                        free={free}
                                        setFree={setFree}
                                        user={user}
                                        selectedLanguage={selectedLanguage}
                                        setIsOpen={setIsOpen}
                                        sidebarRef={sidebarRef}
                                        isOpen={isOpen}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    }
                </>
            }
        </>
    );
};

export default Papers;

const Traduccion = ({ trBar, selectedLanguage, setTrOpen, trOpen }) => {
    const [uploadStatus, setUploadStatus] = useState('idle');
    const [translationResult, setTranslationResult] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    const [targetLanguage, setTargetLanguage] = useState("es");

    const handleInputChange = (e) => {
        setTargetLanguage(e.target.value);
    };

    const handleTranslate = async () => {
        const fileInput = document.getElementById('fileInput');
        if (fileInput.files.length > 0) {
            const file = fileInput.files[0];
            const formData = new FormData();
            formData.append('file', file);
            formData.append('targetLanguage', targetLanguage);

            setUploadStatus('loading');

            try {
                const response = await fetch('https://vortex-backend-wyg2.onrender.com/translate', {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    const result = await response.json();
                    setTranslationResult(result);
                    console.log(result)
                    setUploadStatus('success');
                } else {
                    throw new Error('Failed to translate');
                }
            } catch (error) {
                setErrorMessage(error.message);
                setUploadStatus('error');
            }
        }
    };


    const close = () => {
        setTrOpen(false)
    }

    return (
        <div
            ref={trBar}
            className={`fixed inset-y-0 right-0 w-96 rounded-l-lg bg-gray-100 transform transition-transform overflow-y-auto ${trOpen ? 'translate-x-0' : '-translate-x-full'}`}>
            <div className="flex justify-center">
                <div className='w-5/6 flex pt-12 justify-between items-center'>
                    <p className="text-xl font-semibold text-gray-700">{selectedLanguage === "es-ES" ? "Traduce" : "Translate"}</p>
                    <XIcon onClick={close} className='text-gray-700 w-5 cursor-pointer h-5 hover:text-gray-900' />
                </div>
            </div>
            <div className='flex justify-center'>
                <div className='w-5/6 pt-10'>
                    <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{selectedLanguage === "es" ? "¿A qué idioma desea traducir?" : "Target language"}</label>
                </div>
            </div>
            <div className='flex items-center justify-center mt-4 mb-10'>
                <div className='w-5/6 '>
                    <form class="">
                        <select value={targetLanguage} onChange={handleInputChange} id="countries" class="bg-gray-50 px-5 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option value="es" selected>{selectedLanguage === "es" ? "Español" : "Spanish"}</option>
                            <option value="en">{selectedLanguage === "es" ? "Inglés" : "English"}</option>
                            <option value="de">{selectedLanguage === "es" ? "Alemán" : "German"}</option>
                            <option value="fr">{selectedLanguage === "es" ? "Frances" : "French"}</option>
                            <option value="ru">{selectedLanguage === "es" ? "Ruso" : "Russian"}</option>
                        </select>
                    </form>
                </div> 
            </div>
            <div className='flex justify-center'>
                <div className="w-5/6">
                    <label htmlFor="fileInput" className="relative cursor-pointer cursor-pointer bg-white border-2 border-gray-300 rounded w-full px-5 py-2">
                        <input
                            id="fileInput"
                            type="file"
                            className="absolute top-0 left-0 h-full w-full opacity-0"
                        />
                        <span className="text-gray-400"> {selectedLanguage === "es-ES" ? "Seleccione un archivo" : "Select a file"} </span>
                    </label>
                </div>
            </div>
            <div className='flex justify-center py-6'>
                <div className='w-5/6'>
                    <div className='w-3/5 flex justify-center'>
                        <button onClick={handleTranslate} className='bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-5 rounded-lg'>
                            {selectedLanguage === "es-ES" ? "Traducir" : "Translate"}
                        </button>
                    </div>
                </div>
            </div>
            <div className='flex justify-center py-2'>
                {uploadStatus === 'loading' && <p> {selectedLanguage === "es-ES" ? "Cargando..." : "Loading..."} </p>}
                {uploadStatus === 'success' && <p>  {selectedLanguage === "es-ES" ? "Traducción exitosa" : "Translation Successful"} </p>}
                {uploadStatus === 'error' && <p>Error: {errorMessage}</p>}
            </div>
            {uploadStatus === 'success' && translationResult && (
                <div className='text-center p-4'>
                    <p className='text-lg'>Translation:</p>
                    <p className='text-gray-600'>{translationResult.translatedText}</p>
                </div>
            )}

        </div>
    );
};

const Result = ({ user, citationRefs, handleDocumentSelection, savedPapers, savedStates, saved, handleSaving, saveToFirebase, handlePaperSelection, signIn, data, recommendations, selectedLanguage }) => {

    const [expandedPapers, setExpandedPapers] = useState({});

    const toggleText = (index) => {
        setExpandedPapers(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const [citationPopup, setCitationPopup] = useState({ show: false, citation: '' });
    const [showCopiedMessage, setShowCopiedMessage] = useState(false);

    const formatCitationAPA = (paper) => {
        const authors = `${paper.authors[0]} ${paper.authors.length > 1 ? "et al" : ""}`;
        const year = paper?.year || "";
        const title = paper?.title || "";
        const journal = paper?.journal?.name || "";
        const volume = paper?.volume || "";
        const pages = paper?.pages || "";
        const url = paper?.openAccessPdf?.url || paper?.url;
        return `${authors} (${year}). ${title}. <i>${journal}</i>, ${volume}, ${pages} <br/> <p style="color: #2563eb;">${url}</p>`;
    };

    const formatCitationMLA = (paper) => {
        const authors = `${paper.authors[0]} ${paper.authors.length > 1 ? "et al" : ""}`;
        const title = paper?.title || "";
        const journal = paper?.journal?.name || "";
        const volume = paper?.volume || "";
        const number = paper?.number || "";
        const year = paper?.year || "";
        const pages = paper?.pages || "";
        return `${authors}. "${title}." <i>${journal}</i>, vol. ${volume}, no. ${number}, ${year}, pp. ${pages}.`;
    };

    const formatCitationChicago = (paper) => {
        const authors = `${paper.authors[0]} ${paper.authors.length > 1 ? "et al" : ""}`
        const title = paper?.title || "";
        const journal = paper?.journal?.name || "";
        const volume = paper?.volume || "";
        const number = paper?.number || "";
        const year = paper?.year || "";
        const pages = paper?.pages || "";
        return `${authors}. "${title}." <i>${journal}</i> ${volume}, no. ${number} (${year}): ${pages}.`;
    };

    const formatCitationBibTeX = (paper) => {
        const authors = `${paper.authors[0]} ${paper.authors.length > 1 ? "et al" : ""}`
        const title = paper?.title || "";
        const journal = paper?.journal?.name || "";
        const year = paper?.year || "";
        const volume = paper?.volume || "";
        const number = paper?.number || "";
        const pages = paper?.pages || "";
        return `@article{${authors.replace(/\s/g, "")}_${year},\n  author = {${authors}},\n  title = {${title}},\n  journal = {${journal}},\n  year = {${year}},\n  volume = {${volume}},\n  number = {${number}},\n  pages = {${pages}}\n}`;
    };

    const showCitationPopup = (paper) => {
        const citationAPA = formatCitationAPA(paper);
        const citationMLA = formatCitationMLA(paper);
        const citationChicago = formatCitationChicago(paper);
        const citationBibTeX = formatCitationBibTeX(paper);
        setCitationPopup({
            show: true,
            citationAPA,
            citationMLA,
            citationChicago,
            citationBibTeX,
            selectedTab: "APA",
        });
    };

    const closeCitationPopup = () => {
        setCitationPopup({ show: false, citation: '' });
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setShowCopiedMessage(true);
        setTimeout(() => {
            setShowCopiedMessage(false);
        }, 1500);
    };

    return (
        <>
            {citationPopup.show && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="modal-overlay absolute w-full h-full bg-gray-900 bg-opacity-50"></div>
                    <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto shadow-xl z-50">
                        <div className="">
                            <div className="w-full flex bg-gray-100">
                                <div className="w-1/6"></div>
                                <div className="w-4/6 flex justify-center py-3">
                                    <p className="text-lg font-semibold text-gray-700">
                                        {selectedLanguage === "es-ES" ? "Citar fuente" : "Cite source"}
                                    </p>
                                </div>
                                <div className="w-1/6 flex items-center justify-center">
                                    <XCircleIcon
                                        onClick={closeCitationPopup}
                                        className="text-gray-700 h-6 w-6 hover:text-gray-800 cursor-pointer"
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-4">
                                <div
                                    className={`py-2 px-4 flex justify-center cursor-pointer ${citationPopup.selectedTab === "APA"
                                        ? "bg-gray-200 text-gray-800"
                                        : "bg-gray-100 text-gray-600"
                                        }`}
                                    onClick={() =>
                                        setCitationPopup({ ...citationPopup, selectedTab: "APA" })
                                    }
                                >
                                    APA
                                </div>
                                <div
                                    className={`py-2 px-4 flex justify-center cursor-pointer ${citationPopup.selectedTab === "MLA"
                                        ? "bg-gray-200 text-gray-800"
                                        : "bg-gray-100 text-gray-600"
                                        }`}
                                    onClick={() =>
                                        setCitationPopup({ ...citationPopup, selectedTab: "MLA" })
                                    }
                                >
                                    MLA
                                </div>
                                <div
                                    className={`py-2 px-4 flex justify-center cursor-pointer ${citationPopup.selectedTab === "Chicago"
                                        ? "bg-gray-200 text-gray-800"
                                        : "bg-gray-100 text-gray-600"
                                        }`}
                                    onClick={() =>
                                        setCitationPopup({ ...citationPopup, selectedTab: "Chicago" })
                                    }
                                >
                                    Chicago
                                </div>
                                <div
                                    className={`py-2 px-4 flex justify-center cursor-pointer ${citationPopup.selectedTab === "BibTeX"
                                        ? "bg-gray-200 text-gray-800"
                                        : "bg-gray-100 text-gray-600"
                                        }`}
                                    onClick={() =>
                                        setCitationPopup({ ...citationPopup, selectedTab: "BibTeX" })
                                    }
                                >
                                    BibTeX
                                </div>
                            </div>
                            <div className="text-gray-600 rounded bg-gray-100 text-sm light p-6 relative">
                                <div
                                    className="citation-text font-sans break-words"
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            citationPopup.selectedTab === "APA"
                                                ? citationPopup.citationAPA
                                                : citationPopup.selectedTab === "MLA"
                                                    ? citationPopup.citationMLA
                                                    : citationPopup.selectedTab === "Chicago"
                                                        ? citationPopup.citationChicago
                                                        : citationPopup.citationBibTeX,
                                    }}
                                ></div>
                                <PiCopyBold
                                    className="my-2 w-5 h-5 cursor-pointer hover:text-gray-800 text-gray-600"
                                    onClick={() =>
                                        copyToClipboard(
                                            citationPopup[`citation${citationPopup.selectedTab}`]
                                        )
                                    }
                                    title="Copy to clipboard"
                                />
                                {showCopiedMessage && (
                                    <div className="absolute bottom-2 border rounded-full left-1/2 transform -translate-x-1/2 bg-white border-green-500 text-green-500 px-5 py-2 rounded">
                                        {selectedLanguage === "es-ES" ? "Texto copiado" : "Text copied"}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {recommendations?.map((paper, index) => (
                <div
                    key={index}
                    ref={el => citationRefs.current[index] = el}
                    className='flex justify-center'
                >
                    <div className='p-4 w-11/12 sm:w-4/5 rounded-xl bg-white my-3 shadow-2xl'>
                        <div className='flex flex-col w-full justify-evenly'>
                            <a className='hover:underline w-full py-2 flex flex-col sm:flex-row items-start sm:items-center'>
                                <div className='w-full flex sm:ml-4'>
                                    <p className='w-8 h-8 flex-shrink-0 flex items-center justify-center rounded-full text-sm text-white bg-blue-600'>
                                        {index + 1}
                                    </p>
                                    <p
                                        onClick={() =>
                                            paper?.url
                                                ? window.open(paper.url, '_blank', 'noopener,noreferrer')
                                                : null
                                        }
                                        className={`text-gray-500 text-sm ml-2 ${paper?.url ? 'cursor-pointer hover:text-blue-600' : ''
                                            } line-clamp-3 pr-2`}
                                    >
                                        {paper?.title}
                                    </p>
                                </div>
                            </a>
                        </div>
                        <div className=''>
                            {paper?.summary &&
                                <div className='flex w-full justify-center'>
                                    <div className='w-full sm:w-11/12 my-2 rounded-xl p-2 bg-gray-100'>
                                        <p style={expandedPapers[index] ? {} : {
                                            display: '-webkit-box',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: '3',
                                            overflow: 'hidden'
                                        }} className='text-gray-800 font-sans text-justify'>
                                            {paper?.summary}
                                        </p>
                                        <button onClick={() => toggleText(index)} className='text-blue-500 font-sans'>
                                            {expandedPapers[index] ? selectedLanguage === "es-ES" ? "Ver menos" : "See less" : selectedLanguage === "es-ES" ? "Ver más" : "See more"}
                                        </button>
                                    </div>
                                </div>
                            }
                            <div className='flex justify-center pt-3 pb-6'>
                                <div className='w-full sm:w-11/12 sm:flex'>
                                    <div className='w-full sm:w-1/2 flex justify-start'>
                                        <a className='text-gray-500 flex items-center font-semibold pb-2 text-sm'>
                                            {paper?.journal?.name}
                                            {topJournals.includes(paper?.journal?.name) &&
                                                <span className='flex items-center ml-2'>
                                                    {selectedLanguage === "es-ES" ?
                                                        <span className='flex items-center'>
                                                            <PiSparkleBold className='w-6 h-6 text-yellow-500 mr-1 font-semibold' />
                                                            <p className='font-semibold text-gray-600'>Revista influyente</p>
                                                        </span> :
                                                        <span className='flex'>
                                                            <PiSparkleBold className='mr-1 w-6 h-6 text-yellow-500' />
                                                            <p className='text-gray-600 font-semibold'>Influential Journal</p>
                                                        </span>
                                                    }
                                                </span>
                                            }
                                        </a>
                                    </div>
                                    <div className='flex w-full sm:w-1/2'>
                                        {paper?.authors && paper.authors.length > 0 &&
                                            <div className='w-1/2 flex justify-start pr-2'>
                                                <p className='text-gray-500 text-sm font-light italic'>
                                                    {`${paper.authors[0]} ${paper.authors.length > 1 ? "et al" : ""}`}
                                                </p>
                                            </div>
                                        }
                                        {(paper?.citations || paper?.year) &&
                                            <><div className='w-1/3 flex justify-center px-2'>
                                                {paper?.citations &&
                                                    <p className='text-gray-500 text-sm'>
                                                        {paper?.citations} {selectedLanguage === "es-ES" ? "Citas" : "Citations"}
                                                    </p>
                                                }
                                            </div>
                                                <div className='w-1/6 flex justify-center'>
                                                    <p className='text-gray-500 text-sm'>{paper?.year}</p>
                                                </div></>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="border-t px-1 sm:px-6 flex border-gray-300">
                                <div className="w-1/2 sm:w-4/5 flex justify-evenly items-center py-5">
                                    <div className='w-2/3 sm:w-1/3'>
                                    </div>
                                    <div className='hidden pl-16 sm:w-1/3 sm:flex justify-center'>
                                    </div>
                                </div>
                                <div className="w-1/2 sm:w-1/3 flex items-center py-5 justify-evenly">
                                    {paper?.url && (
                                        <a
                                            href={paper.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="flex items-center text-gray-600 cursor-pointer font-semibold hover:underline"
                                        >
                                            <img src={pdfOne} className="w-6 h-auto text-red-500 mr-1" alt="PDF" />
                                            <p className="pl-1 hidden sm:flex text-sm">
                                                {selectedLanguage === "es-ES" ? "PDF" : "PDF"}
                                            </p>
                                        </a>
                                    )}

                                    <div
                                        className="flex items-center cursor-pointer"
                                        onClick={() => {
                                            if (user) {
                                                handleSaving(paper);
                                            } else {
                                                signIn();
                                            }
                                        }}
                                    >
                                        {savedStates[paper.url] ? (
                                            <>
                                                <img src={checkOne} className="h-6 w-auto text-green-500" alt="Saved" />
                                                <p className="pl-1 hidden sm:flex font-semibold hover:underline pb-1 text-sm text-gray-600">
                                                    {selectedLanguage === "es-ES" ? "Guardado" : "Saved"}
                                                </p>
                                            </>
                                        ) : (
                                            <>
                                                <img
                                                    src={saveOne}
                                                    className="h-6 w-auto text-green-500"
                                                    alt="Save"
                                                />
                                                <p
                                                    className="pl-1 pb-1 font-semibold hidden sm:flex hover:text-gray-700 hover:underline text-sm text-gray-600"
                                                >
                                                    {selectedLanguage === "es-ES" ? "Guardar" : "Save"}
                                                </p>
                                            </>
                                        )}
                                    </div>

                                    <div
                                        className="flex items-center cursor-pointer"
                                        onClick={() => showCitationPopup(paper)}
                                    >
                                        <img
                                            src={quoteOne}
                                            className="h-6 hover:text-green-700 font-semibold w-auto text-green-500"
                                            alt="Cite"
                                        />
                                        <p
                                            className="pl-1 hidden sm:flex hover:text-gray-700 hover:underline text-sm text-gray-600"
                                        >
                                            {selectedLanguage === "es-ES" ? "Citar" : "Cite"}
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="fa-comment-icon">
                            <FaCommentAlt className="w-6 h-6 text-blue-500" />
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}


const Sidebar = ({ sidebarRef, yearRange, yearBefore, yearAfter, setYearRange, setYearBefore, setYearAfter, handleCheckboxChange, selectedLanguage, free, year, setYear, handleSearch, setFree, isOpen, setIsOpen }) => {

    const [type, setType] = useState(false);
    const [field, setField] = useState(false);

    const handleCheckboxChangefree = (event) => {
        const isChecked = event.target.checked;
        setFree(isChecked ? "isOpenAccess" : "");
    };

    const toggleTypes = () => {
        setType(!type);
    };

    const toggleFields = () => {
        setField(!field);
    };

    const [years, setYears] = useState(false)

    const toggleYears = () => {
        setYears(!years)
    }

    const close = () => {
        setIsOpen(false);
    };

    return (
        <div
            ref={sidebarRef}
            className={`fixed inset-y-0 right-0 w-96 bg-white shadow-xl transform transition-transform duration-200 ease-in-out overflow-y-auto ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}
        >
            {/* Header */}
            <div className="sticky top-0 z-10 bg-white border-b border-gray-100 px-6 py-4">
                <div className='flex justify-between items-center'>
                    <p className="text-xl font-bold text-gray-800">
                        {selectedLanguage === "es-ES" ? "Filtros" : "Filters"}
                    </p>
                    <button onClick={close} className="text-gray-400 hover:text-gray-600 transition-colors">
                        <XIcon className='w-5 h-5' />
                    </button>
                </div>
            </div>

            {/* Free Access Toggle */}
            <div className="px-6 py-4">
                <label className="flex items-center space-x-3 cursor-pointer">
                    <input
                        type='checkbox'
                        onChange={handleCheckboxChangefree}
                        checked={free === "isOpenAccess"}
                        className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                    />
                    <span className="text-sm text-gray-600">{selectedLanguage === "es-ES" ? "Acceso gratis" : "Free access"}</span>
                </label>
            </div>

            {/* Publication Types Section */}
            <div
                onClick={toggleTypes}
                className='flex items-center justify-between px-6 py-4 hover:bg-gray-50 cursor-pointer transition-colors border-t border-gray-100'
            >
                <div className='flex items-center space-x-2'>
                    <IoSparklesSharp className='w-5 h-5 text-indigo-600' />
                    <p className='font-medium text-gray-700'>
                        {selectedLanguage === "es-ES" ? "Tipo de publicación" : "Publication type"}
                    </p>
                </div>
                <FaChevronDown className={`w-4 h-4 text-gray-400 transition-transform ${type ? 'rotate-180' : ''}`} />
            </div>

            {type && (
                <div className="py-2">
                    {[
                        { value: "Review", label: "Review" },
                        { value: "JournalArticle", label: selectedLanguage === "es-ES" ? "Artículo de Revista" : "Journal Article" },
                        { value: "CaseReport", label: selectedLanguage === "es-ES" ? "Reporte de Caso" : "Case Report" },
                        { value: "ClinicalTrial", label: selectedLanguage === "es-ES" ? "Ensayo clínico" : "Clinical Trial" },
                        { value: "Dataset", label: "Dataset" },
                        { value: "Editorial", label: "Editorial" },
                        { value: "LettersAndComments", label: selectedLanguage === "es-ES" ? "Cartas y Comentario" : "Letters And Comments" },
                        { value: "MetaAnalysis", label: selectedLanguage === "es-ES" ? "Meta Análisis" : "Meta Analysis" },
                        { value: "News", label: selectedLanguage === "es-ES" ? "Noticia" : "News" },
                        { value: "Study", label: selectedLanguage === "es-ES" ? "Estudio" : "Study" },
                        { value: "Book", label: selectedLanguage === "es-ES" ? "Libro" : "Book" },
                        { value: "BookSection", label: selectedLanguage === "es-ES" ? "Sección de Libro" : "Book Section" }
                    ].map((item, index) => (
                        <div key={index} className="flex items-center px-6 py-2 hover:bg-gray-50">
                            <input
                                type="checkbox"
                                value={item.value}
                                onChange={(event) => handleCheckboxChange(event, 'publication')}
                                className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                            />
                            <label className="ml-3 text-sm text-gray-600">{item.label}</label>
                        </div>
                    ))}
                </div>
            )}

            {/* Fields of Study Section */}
            <div
                onClick={toggleFields}
                className='flex items-center justify-between px-6 py-4 hover:bg-gray-50 cursor-pointer transition-colors border-t border-gray-100'
            >
                <div className='flex items-center space-x-2'>
                    <IoSparklesSharp className='w-5 h-5 text-indigo-600' />
                    <p className='font-medium text-gray-700'>
                        {selectedLanguage === "es-ES" ? "Áreas de estudio" : "Fields of study"}
                    </p>
                </div>
                <FaChevronDown className={`w-4 h-4 text-gray-400 transition-transform ${field ? 'rotate-180' : ''}`} />
            </div>

            {field && (
                <div className="py-2">
                    {/* Fields checkboxes - similar structure to Publication Types */}
                    {/* Keep all your existing fields, just updating the UI */}
                    {[
                        { value: "Computer Science", label: selectedLanguage === "es-ES" ? "Ciencias de la Computación" : "Computer Science" },
                        { value: "Medicine", label: selectedLanguage === "es-ES" ? "Medicina" : "Medicine" },
                        { value: "Chemistry", label: selectedLanguage === "es-ES" ? "Química" : "Chemistry" },
                        { value: "Biology", label: selectedLanguage === "es-ES" ? "Biología" : "Biology" },
                        { value: "Materials Science", label: selectedLanguage === "es-ES" ? "Ciencia de los Materiales" : "Materials Science" },
                        { value: "Physics", label: selectedLanguage === "es-ES" ? "Física" : "Physics" },
                        { value: "Geology", label: selectedLanguage === "es-ES" ? "Geología" : "Geology" },
                        { value: "Psychology", label: selectedLanguage === "es-ES" ? "Psicología" : "Psychology" },
                        { value: "Art", label: selectedLanguage === "es-ES" ? "Arte" : "Art" },
                        { value: "History", label: selectedLanguage === "es-ES" ? "Historia" : "History" },
                        { value: "Geography", label: selectedLanguage === "es-ES" ? "Geografía" : "Geography" },
                        { value: "Sociology", label: selectedLanguage === "es-ES" ? "Sociología" : "Sociology" },
                        { value: "Business", label: selectedLanguage === "es-ES" ? "Empresas" : "Business" },
                        { value: "Political Science", label: selectedLanguage === "es-ES" ? "Ciencias Políticas" : "Political Science" },
                        { value: "Economics", label: selectedLanguage === "es-ES" ? "Economía" : "Economics" },
                        { value: "Philosophy", label: selectedLanguage === "es-ES" ? "Filosofía" : "Philosophy" },
                        { value: "Mathematics", label: selectedLanguage === "es-ES" ? "Matemáticas" : "Mathematics" },
                        { value: "Engineering", label: selectedLanguage === "es-ES" ? "Ingeniería" : "Engineering" },
                        { value: "Environmental Science", label: selectedLanguage === "es-ES" ? "Ciencias Ambientales" : "Environmental Science" },
                        { value: "Agricultural and Food Sciences", label: selectedLanguage === "es-ES" ? "Ciencias de la Agricultura y Alimentos" : "Agricultural and Food Sciences" },
                        { value: "Education", label: selectedLanguage === "es-ES" ? "Educación" : "Education" },
                        { value: "Law", label: selectedLanguage === "es-ES" ? "Leyes" : "Law" },
                        { value: "Linguistics", label: selectedLanguage === "es-ES" ? "Lingüística" : "Linguistics" }
                    ].map((item, index) => (
                        <div key={index} className="flex items-center px-6 py-2 hover:bg-gray-50">
                            <input
                                type="checkbox"
                                value={item.value}
                                onChange={(event) => handleCheckboxChange(event, 'field')}
                                className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                            />
                            <label className="ml-3 text-sm text-gray-600">{item.label}</label>
                        </div>
                    ))}
                </div>
            )}

            {/* Years Section */}
            <div
                onClick={toggleYears}
                className='flex items-center justify-between px-6 py-4 hover:bg-gray-50 cursor-pointer transition-colors border-t border-gray-100'
            >
                <div className='flex items-center space-x-2'>
                    <IoSparklesSharp className='w-5 h-5 text-indigo-600' />
                    <p className='font-medium text-gray-700'>
                        {selectedLanguage === "es-ES" ? "Años" : "Years"}
                    </p>
                </div>
                <FaChevronDown className={`w-4 h-4 text-gray-400 transition-transform ${years ? 'rotate-180' : ''}`} />
            </div>

            {years && (
                <div className="px-6 py-4 space-y-4">
                    <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">
                            {selectedLanguage === "es-ES" ? "Significa: Durante 2019" : "Meaning: During 2019"}
                        </label>
                        <input
                            value={year}
                            onChange={(e) => { setYear(e.target.value) }}
                            placeholder="2019"
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-gray-900 placeholder-gray-400"
                        />
                    </div>

                    <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">
                            {selectedLanguage === "es-ES" ? "Significa: Entre 2015 y 2020" : "Meaning: Between 2015 and 2020"}
                        </label>
                        <input
                            value={yearRange}
                            onChange={(e) => { setYearRange(e.target.value) }}
                            placeholder="2015-2020"
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-gray-900 placeholder-gray-400"
                        />
                    </div>

                    <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">
                            {selectedLanguage === "es-ES" ? "Significa: Durante o después del 2010" : "Meaning: During or after 2010"}
                        </label>
                        <input
                            value={yearAfter}
                            onChange={(e) => { setYearAfter(e.target.value) }}
                            placeholder="2010-"
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-gray-900 placeholder-gray-400"
                        />
                    </div>

                    <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">
                            {selectedLanguage === "es-ES" ? "Significa: Durante o antes del 2013" : "Meaning: During or before 2013"}
                        </label>
                        <input
                            value={yearBefore}
                            onChange={(e) => { setYearBefore(e.target.value) }}
                            placeholder="-2013"
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-gray-900 placeholder-gray-400"
                        />
                    </div>
                </div>
            )}

            {/* Search Button */}
            <div className='sticky bottom-0 bg-white border-t border-gray-100 px-6 py-4 mt-4'>
                <button
                    onClick={handleSearch}
                    className='w-full px-4 py-2 bg-indigo-600 hover:bg-indigo-700 text-white font-medium rounded-lg transition-colors'
                >
                    {selectedLanguage === "es-ES" ? "Buscar" : "Search"}
                </button>
            </div>
        </div>
    );

};