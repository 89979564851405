import { useState } from "react";
import { ImQuotesLeft } from "react-icons/im";
import { DocumentTextIcon, XIcon, ClipboardIcon } from "@heroicons/react/solid";
import { doc, deleteDoc } from 'firebase/firestore';
import { db } from "../firebase";
import Header from "./header";
import Footer from "./footer";

const CitationModal = ({ citation, onClose, selectedLanguage }) => (
    <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="absolute inset-0 bg-gray-900/50 backdrop-blur-sm" onClick={onClose} />
        <div className="bg-white w-11/12 max-w-2xl rounded-2xl shadow-xl z-50 overflow-hidden">
            <div className="flex items-center justify-between bg-gray-50 px-6 py-4 border-b border-gray-200">
                <h3 className="text-lg font-semibold text-gray-900">
                    {selectedLanguage === "es-ES" ? "Citar" : "Cite"}
                </h3>
                <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                    <XIcon className="w-5 h-5" />
                </button>
            </div>
            <div className="p-6">
                <div className="bg-gray-50 rounded-xl p-4 group relative">
                    <p className="text-gray-600 font-medium">{citation}</p>
                    <button
                        onClick={() => navigator.clipboard.writeText(citation)}
                        className="absolute right-4 top-4 opacity-0 group-hover:opacity-100 transition-opacity"
                        title="Copy to clipboard"
                    >
                        <ClipboardIcon className="w-5 h-5 text-gray-500 hover:text-gray-700" />
                    </button>
                </div>
            </div>
        </div>
    </div>
);

const PaperCard = ({ paper, selectedLanguage, onDelete, onCite, isExpanded, onToggle }) => (
    <div className="bg-white rounded-xl border border-gray-200 hover:border-gray-300 transition-colors">
        <div className="p-6">
            {/* Title and Document Icon */}
            <div className="flex items-start space-x-4">
                <DocumentTextIcon className="w-5 h-5 text-indigo-500 mt-1" />
                <div className="flex-1">
                    <a
                        href={paper?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-900 font-medium hover:text-indigo-600 transition-colors"
                    >
                        {paper?.title}
                    </a>
                    <p className="text-sm text-gray-500 mt-1">{paper?.year}</p>
                </div>
            </div>

            {/* Abstract */}
            {(paper?.tldr || paper?.translatedAbstract) && (
                <div className="mt-4">
                    <div className="bg-gray-50 rounded-xl p-4">
                        <p className={`text-gray-600 ${!isExpanded ? 'line-clamp-3' : ''}`}>
                            {paper?.tldr || paper?.translatedAbstract}
                        </p>
                        <button
                            onClick={onToggle}
                            className="mt-2 text-indigo-600 hover:text-indigo-700 text-sm font-medium"
                        >
                            {isExpanded
                                ? selectedLanguage === "es-ES" ? "Ver menos" : "Show less"
                                : selectedLanguage === "es-ES" ? "Ver más" : "Show more"}
                        </button>
                    </div>
                </div>
            )}

            {/* Paper Details */}
            <div className="mt-4 grid grid-cols-4 gap-4 text-sm text-gray-500">
                <div>{paper?.journal?.name}</div>
                <div className="italic">{paper?.author ? `${paper.author.split(',')[0]} et al` : ''}</div>
                <div>{paper?.citationCount} {selectedLanguage === "es-ES" ? "Citas" : "Citations"}</div>
                <div className="text-right">{paper?.year}</div>
            </div>
        </div>

        {/* Actions */}
        <div className="px-6 py-4 border-t border-gray-100 flex justify-end space-x-3">
            <button
                onClick={onDelete}
                className="px-4 py-2 text-sm font-medium text-red-600 hover:bg-red-50 rounded-lg transition-colors"
            >
                {selectedLanguage === "es-ES" ? "Eliminar" : "Delete"}
            </button>
            <button
                onClick={onCite}
                className="px-4 py-2 text-sm font-medium text-indigo-600 hover:bg-indigo-50 rounded-lg transition-colors inline-flex items-center"
            >
                <ImQuotesLeft className="w-4 h-4 mr-2" />
                {selectedLanguage === "es-ES" ? "Citar" : "Cite"}
            </button>
        </div>
    </div>
);

const Saved = ({ selectedLanguage, savedPapers }) => {
    const [expandedPapers, setExpandedPapers] = useState({});
    const [citationPopup, setCitationPopup] = useState({ show: false, citation: '' });

    const sortedPapers = savedPapers.sort((a, b) => b.timestamp - a.timestamp);

    const toggleText = (index) => {
        setExpandedPapers(prev => ({ ...prev, [index]: !prev[index] }));
    };

    const formatCitationAPA = (paper) => {
        const authors = paper?.author || "";
        const year = paper?.year || "";
        const title = paper?.title || "";
        const journal = paper?.journal?.name || "";
        const volume = paper?.volume || "";
        const pages = paper?.pages || "";
        return `${authors} (${year}). ${title}. ${journal}, ${volume}, ${pages}`;
    };

    const handleDelete = async (paper) => {
        try {
            await deleteDoc(doc(db, "s3", paper.uid));
            alert("Document Deleted");
        } catch (error) {
            alert(error.message);
        }
    };

    return (
        <>
            <Header />
            <main className="max-w-5xl mx-auto px-4 py-12">
                {/* Header */}
                <div className="flex items-center space-x-3 border-b border-gray-200 pb-4 mb-8">
                    <DocumentTextIcon className="w-6 h-6 text-indigo-600" />
                    <h1 className="text-xl font-semibold text-gray-900">
                        {selectedLanguage === "es-ES" ? "Papers guardados" : "Saved papers"}
                    </h1>
                    <span className="bg-indigo-50 text-indigo-600 px-3 py-1 rounded-full text-sm font-medium">
                        {savedPapers.length}
                    </span>
                </div>

                {/* Papers List */}
                <div className="space-y-6">
                    {sortedPapers?.map((paper, index) => (
                        <PaperCard
                            key={index}
                            paper={paper}
                            selectedLanguage={selectedLanguage}
                            onDelete={() => handleDelete(paper)}
                            onCite={() => setCitationPopup({
                                show: true,
                                citation: formatCitationAPA(paper)
                            })}
                            isExpanded={expandedPapers[index]}
                            onToggle={() => toggleText(index)}
                        />
                    ))}
                </div>
            </main>

            {citationPopup.show && (
                <CitationModal
                    citation={citationPopup.citation}
                    onClose={() => setCitationPopup({ show: false, citation: '' })}
                    selectedLanguage={selectedLanguage}
                />
            )}
            <Footer />
        </>
    );
};

export default Saved;