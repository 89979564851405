import React from 'react';
import { Cloud, Target, PieChart, MessageCircle, FastForward, Database } from 'lucide-react';

const translations = {
    'en-US': {
        features: "Features",
        title: "A Solution Engine",
        subtitle: "Imagine finding the <span>best solutions</span> to every problem, and always working on the <span>most important goals</span>. That's priceless.",
        howWeHelp: "Here's how we help you do it:",
        featureCards: [
            {
                title: "Large database",
                description: "Search accross +220 million academic papers"
            },
            {
                title: "Accurate results",
                description: "VortiX finds the precise papers you need, with clear explanations about why they matter."
            },
            {
                title: "Lightning fast",
                description: "For every question"
            },
            {
                title: "Chat mode",
                description: "For more direct answers"
            }
        ]
    },
    'es-ES': {
        features: "Características",
        title: "Un Motor de Soluciones",
        subtitle: "Imagina encontrar las <span>mejores soluciones</span> para cada problema y trabajar siempre en los <span>objetivos más importantes</span>. Eso no tiene precio.",
        howWeHelp: "Así es como te ayudamos a hacerlo:",
        featureCards: [
            {
                title: "Una gran base de datos",
                description: "Puedes buscar entre más de 220 millones de documentos académicos"
            },
            {
                title: "Resultados precisos",
                description: "VortiX encuentra los artículos exactos que necesitas, con explicaciones claras sobre por qué son importantes."
            },
            {
                title: "Rápido y preciso",
                description: "Para cada búsqueda"
            },
            {
                title: "Modo chat",
                description: "Para respuestas más directas."
            }
        ]
    }
};

const FeatureCard = ({ icon: Icon, title, description }) => (
    <div className="flex flex-col items-center text-center">
        <div className="bg-blue-600 p-4 rounded-xl mb-4">
            <Icon className="w-8 h-8 text-white" />
        </div>
        <h3 className="text-lg font-semibold mb-2">{title}</h3>
        <p className="text-gray-600 text-sm">{description}</p>
    </div>
);

const SolutionEngineFeatures = ({ selectedLanguage }) => {
    const t = translations[selectedLanguage] || translations['en-US'];

    return (
        <div className="container mx-auto px-4 py-16">
            <div className="text-center mb-12">
                <p className="text-blue-600 font-semibold mb-2">{t.features}</p>
                <h2 className="text-4xl font-bold mb-4">{t.title}</h2>
                <p className="text-xl text-gray-600 max-w-2xl mx-auto"
                    dangerouslySetInnerHTML={{ __html: t.subtitle }}>
                </p>
                <p className="text-xl mt-4">{t.howWeHelp}</p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                {t.featureCards.map((card, index) => (
                    <FeatureCard
                        key={index}
                        icon={[Database, Target, FastForward, MessageCircle][index]}
                        title={card.title}
                        description={card.description}
                    />
                ))}
            </div>
        </div>
    );
};

export default SolutionEngineFeatures;