import { useState, useEffect } from "react";
import { collection, addDoc } from 'firebase/firestore';
import { Link } from "react-router-dom";
import { db } from "../firebase";
import {
    LinkIcon,
    Search,
    BookOpen,
    Mail,
    ArrowRight,
    Facebook,
    Linkedin,
    Youtube,
    MessageCircle
} from 'lucide-react';

const FooterLink = ({ to, icon: Icon, children }) => (
    <Link
        to={to}
        className="flex items-center space-x-2 text-gray-600 hover:text-gray-900 transition-colors py-2"
    >
        {Icon && <Icon className="w-4 h-4" />}
        <span className="text-sm">{children}</span>
    </Link>
);

const SocialIcon = ({ icon: Icon, href, label }) => (
    <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={label}
        className="text-gray-500 hover:text-gray-700 transition-colors"
    >
        <Icon className="w-5 h-5" />
    </a>
);

const Footer = ({ selectedLanguage, user }) => {
    const [email, setEmail] = useState("");
    const emailRef = collection(db, "emails");

    const translations = {
        'en-US': {
            about: "About",
            joinUs: "Join us",
            research: "Research",
            search: "Search",
            emailPlaceholder: "Enter your email",
            subscribe: "Subscribe to our newsletter",
            subscribed: "Thank you for subscribing!",
            social: "Follow us",
            contact: "Contact us",
            support: "Support"
        },
        'es-ES': {
            about: "Acerca de",
            joinUs: "Únete",
            research: "Investigación",
            search: "Búsqueda",
            emailPlaceholder: "Ingresa tu correo",
            subscribe: "Suscríbete a nuestro boletín",
            subscribed: "¡Gracias por suscribirte!",
            social: "Síguenos",
            contact: "Contáctanos",
            support: "Soporte"
        }
    };

    const t = translations[selectedLanguage] || translations['en-US'];

    const handleSubscribe = async (e) => {
        e.preventDefault();
        if (!email) return;

        try {
            await addDoc(emailRef, {
                uid: user?.uid,
                email: email,
                createdAt: new Date()
            });
            alert(t.subscribed);
            setEmail("");
        } catch (error) {
            console.error("Error subscribing:", error);
        }
    };

    return (
        <footer className="bg-gradient-to-b from-gray-50 to-gray-100">
            <div className="max-w-7xl mx-auto px-4 py-12">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                    {/* About Section */}
                    <div>
                        <h3 className="text-sm font-semibold text-gray-900 uppercase mb-4">
                            {t.about}
                        </h3>
                        <div className="space-y-2">
                            <FooterLink to="/join" icon={LinkIcon}>{t.joinUs}</FooterLink>
                            <FooterLink to="/research" icon={BookOpen}>{t.research}</FooterLink>
                            <FooterLink to="/search" icon={Search}>{t.search}</FooterLink>
                        </div>
                    </div>

                    {/* Contact Section */}
                    <div>
                        <h3 className="text-sm font-semibold text-gray-900 uppercase mb-4">
                            {t.contact}
                        </h3>
                        <div className="space-y-2">
                            <FooterLink to="/support" icon={MessageCircle}>{t.support}</FooterLink>
                            <a
                                href="mailto:contact@vortix.io"
                                className="flex items-center space-x-2 text-gray-600 hover:text-gray-900 transition-colors py-2"
                            >
                                <Mail className="w-4 h-4" />
                                <span className="text-sm">contact@vortix.io</span>
                            </a>
                        </div>
                    </div>

                    {/* Newsletter Section */}
                    <div className="md:col-span-2">
                        <h3 className="text-sm font-semibold text-gray-900 uppercase mb-4">
                            {t.subscribe}
                        </h3>
                        <form onSubmit={handleSubscribe} className="space-y-4">
                            <div className="flex max-w-md">
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder={t.emailPlaceholder}
                                    className="flex-1 px-4 py-2 rounded-l-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                                    required
                                />
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-indigo-600 text-white rounded-r-lg hover:bg-indigo-700 transition-colors flex items-center"
                                >
                                    <ArrowRight className="w-4 h-4" />
                                </button>
                            </div>
                        </form>

                        {/* Social Links */}
                        <div className="mt-6">
                            <h4 className="text-sm font-semibold text-gray-900 mb-3">{t.social}</h4>
                            <div className="flex space-x-4">
                                <SocialIcon icon={Facebook} href="https://facebook.com" label="Facebook" />
                                <SocialIcon icon={Linkedin} href="https://linkedin.com" label="LinkedIn" />
                                <SocialIcon icon={Youtube} href="https://youtube.com" label="YouTube" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Bottom Line */}
                <div className="mt-12 pt-8 border-t border-gray-200">
                    <p className="text-center text-sm text-gray-500">
                        © {new Date().getFullYear()} VortiX. All rights reserved.
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;